<template>
	<div id="login">
		<div class="login-wrap" :style="loginbg">
			<div class="login-wrap-container">
				<img class="scan_code" :src="$util.getStaticSrc('admin/login-qrcode.png')"  @click="changeQrcode(2)" v-if="scan_code==1" alt="">
				<img class="scan_code2" :src="$util.getStaticSrc('admin/login-pwd.png')" @click="changeQrcode(1)" v-if="scan_code==2" alt="">
				<!-- <i class="ri-qr-code-line scan_code" v-if="scan_code==1" @click="changeQrcode"></i>
				<i class="iconfont icon-mimadenglu scan_code2" v-if="scan_code==2" @click="scan_code = 1"></i> -->
				
				<div class="login-wrap-container_title">欢 迎 登 陆 </div>
				<template v-if="scan_code==1">
					<div class="login-wrap-container_titlesmall">请使用您本人的账号密码登陆</div>
					<div class="login-wrap-container_nav">
						<div class="lwcn-item" :class="login_type ==1 ?'active':''" @click="login_type=1">密码登录</div>
						<div class="lwcn-item" :class="login_type ==2 ?'active':''" @click="login_type=2">验证码登录</div>
					</div>
					
					<form class="login-wrap-container_form" @submit.prevent="login($event)" v-if="login_type==1">
						<div class="input-group">
							<a-input type="text" id="mobile" v-model:value="mobile" placeholder="请输入登录账号" class="input-field"></a-input>
						</div>
						<div class="input-group">
							<a-input type="password" id="password" v-model:value="password" placeholder="请输入密码" class="input-field"></a-input>
						</div>
						<button type="submit" round class="login-button">登录<i class="iconfont icon-youjiantou"></i></button>
					</form>
					
					<form class="login-wrap-container_form" @submit.prevent="mobileLogin($event)" v-if="login_type==2">
						<div class="input-group">
							<a-input type="text" id="mobile" v-model:value="mobile" placeholder="请输入登录账号" class="input-field"></a-input>
						</div>
						<div class="input-group login-wrap-send-code">
							<a-input type="text" id="code" v-model:value="code" placeholder="请输入验证码" class="input-field" style="width: 70%;"></a-input>
							<button type="button" class="login-button" style="width: 28%;" round @click="showVeriy" v-if="!timer">发送验证码</button>
							<button class="login-button" style="width: 28%;" round :disabled="disabled" v-if="timer">{{time}}s后重新获取</button>
						</div>
						<button type="submit" round class="login-button">登录<i class="iconfont icon-youjiantou"></i></button>
					</form>
				</template>
				<template v-if="scan_code==2">
					<div class="login-wrap-container_titlesmall">手机扫码，安全登录</div>
					<img class="scan_code_img" :src="scan_qrcode" alt="">
					<div class="scan_code_tip">
						<i class="iconfont icon-saoyisao"></i>
						<div>
							<div>打开APP 小程序 微信公众号</div>
							<div>进入商家中心扫一扫登录</div>
						</div>
					</div>
					
				</template>
				
				<div class="lc-tips">建议使用
					<a href="https://wxapp.cqkundian.com/ChromeSetup.exe" target="_blank" title="立即下载">谷歌浏览器</a>
				</div>
			</div>
			<div class="footer-version">
				<span v-html="footer"></span>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import loginModel from '@/api/login'
import tool from '@/util/tool'
export default{
	setup(){
		const state = reactive({
			mobile:'',
			password:'',
			login_type:1,		//1密码登录 2验证码登录
			verify_id:0,
			code:'',
			timer:null,
			time:0,
			scan_code:1,		//1扫码登录 2 其他方式登录
			scan_qrcode:'',		//扫码登录二维码
			uuid:'',			//扫码登录uuid, 用于验证是否扫码
			checkCodeTimer:null,	//计时检测是否扫码登录
			program_id:0,
			footer:'',
			login_back_url:'',
			captchaObj:null,
			is_check:true,
		})

		const opt = useRoute().params
		state.program_id = opt.id

		loginModel.getLoginSet().then(res=>{
			state.footer = res.footer
			state.login_back_url = res.login_back_url

			loginModel.getGeeTestVerify().then(result=>{
				if( result.is_check ){
					
					let data = result.data.geetest_response
					//请检测data的数据结构， 保证data.gt, data.challenge, data.success有值
					initGeetest({
						// 以下配置参数来自服务端 SDK
						gt: data.gt,
						challenge: data.challenge,
						offline: !data.success,
						new_captcha: true,
						product: 'bind',
					}, function (captchaObj) {
						state.captchaObj = captchaObj
						captchaObj.onReady(function(){
							//验证码ready之后才能调用verify方法显示验证码
						}).onSuccess(function(){
							var obj = captchaObj.getValidate();
							sendCode( { geetest_userid:result.data.geetest_userid,...obj } )
						}).onError(function(err){
							console.log('err',err);
						})
					})
				}else{
					state.is_check = false
				}
			})
		})

		const loginbg = computed(()=>{
			return `background-image: url(${state.login_back_url});`
		})

		function showVeriy(){
			if( !state.mobile ){
				tool.message("请先输入手机号");
				return
			}
			if( state.is_check ){
				state.captchaObj.verify()
			}else{
				sendCode( null )
			}
		}

		function login(){
			let data = {
				mobile :state.mobile,
				password:state.password,
				program_id:state.program_id
			}
			loginModel.shopLogin(data)
		}

		function mobileLogin(){
			let data = {
				mobile :state.mobile,
				code:state.code,
				verify:state.verify_id,
				program_id:state.program_id
			}

			loginModel.shopLoginByCode(data)
		}

		function sendCode(verify){
			const TIME_COUNT =60
			loginModel.sendShortMsgCode(state.mobile,1,verify).then(res=>{
				state.verify_id = res
				if (!state.timer) {
					state.time = TIME_COUNT;
					state.timer = setInterval(() => {
						if (state.time > 0 && state.time <= TIME_COUNT) {
							state.time--;
						} else {
							clearInterval(state.timer);
							state.timer = null;
						}
					}, 1000)
				}
			})
		}

		function changeQrcode(type){
			state.scan_code = type
			if( type == 1 && state.checkCodeTimer){
				clearInterval(state.checkCodeTimer)
				return
			}
			loginModel.getShopLoginQrcode(res=>{
				state.scan_qrcode = res.qr_code
				state.uuid = res.uuid
				state.checkCodeTimer = setInterval(function(){
					checkQrcodeLogin()
				},2500)
			})
		}

		function checkQrcodeLogin(){
			let {uuid } = state
			loginModel.shopLoginCheck(uuid,res=>{
				if( res == 2 || res == 1 ) clearInterval(state.checkCodeTimer)
				if( res == -1 ) {
					clearInterval(state.checkCodeTimer)
					changeQrcode(2)
				}
			})
		}
		return{
			...toRefs(state),
			login,
			mobileLogin,
			sendCode,
			changeQrcode,
			checkQrcodeLogin,
			loginbg,
			showVeriy
		}
	}
}
</script>

<style lang="scss">
	html,body{
	    height: 100%;
		margin: 0;
		padding: 0;
	}
	.ai-enter:before { content: "\e766"; }
	
	.login-wrap{
		width:100%;
		border: 1px solid green;
		position:fixed;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		background-image: url(../../assets/img/login-bg.png);
		
		&-send-code{
			display: flex;
			align-items: center;
			
			.login-button{
				margin: 0 0 0 10px;
			}
		}
		
		&-container{
			width: 500px;
			// height: 400px;
			display: table-cell;
			vertical-align: middle;
			position: fixed;
			top: 148px;
			background: #FFFFFF;
			padding: 20px;
			
			.scan_code{
				position: absolute;
				font-size: 70px;
				right: -7px;
				top: -8px;
				cursor: pointer;
				width: 72px;
			}
			.scan_code2{
				position: absolute;
				font-size: 52px;
				right: 0;
				top: 0;
				cursor: pointer;
				width: 56px;
			}
			
			.scan_code_img{
				width: 150px;
				height: 150px;
				margin-top: 20px;
			}
			.scan_code_tip{
				display: flex;
				width: 40%;
				margin: auto;
				align-items: center;
				font-size: 12px;
				color: #999;
				margin-top: 20px;
				
				div{
					text-align: left;
				}
				.iconfont{
					font-size: 36px;
				}
			}
			
			
			&_title{
				width:  75%;
				text-align: left;
				color: #5794ff;
				font-size: 24px;
				font-weight: bold;
				margin: 10px auto;
			}
			&_titlesmall{
				width:  75%;
				text-align: left;
				color: #bbb6c6;
				font-size: 15px;
				margin: 10px auto;
				letter-spacing: 3px;
			}
			
			&_form{
				width: 75%;
				margin: 45px auto;
				.input-group{
					width: 100%;
					margin:40px auto;
				}
				
				.login-button{
					width: 100%;
					background:  #5794ff;
					height: 45px;
					border-radius: 45px;
					border: none;
					color: #FFFFFF;
					cursor: pointer;
				}
				.login-button:hover{
					background: #629bff;
				}
			}
			
			
			&_nav{
				width: 75%;
				height:50px;
				line-height: 50px;
				display: flex;
				margin: auto;
				
				.lwcn-item{
					width: 30%;
					text-align: left;
					position: relative;
					cursor: pointer;
					color: #bbb6c6;
				}
				
				.active{
					color: #5794ff;
				}
				
				.active:after{
					content: '';
					position: absolute;
					height: 3px;
					background: #5794ff;
					bottom: 0;
					width: 20%;
					left:18%;
					border-radius: 3px 3px 0 0;
				}
				
			}
		}
	
		//底部信息
		.footer-version{
			width: 100%;
			height: 30px;
			line-height: 30px;
			background: rgba(0,0,0,0.6);
			position: fixed;
			bottom: 0;
			text-align: center;
			color: #FFFFFF;
			font-size: 12px;
		}
		
		.lc-tips{
			font-size: 12px;
			color: #999;
			margin-top: 15px;
			
			a{
				text-decoration: none;
				color: #29adeb;
			}
		}
	}
	
	
</style>
